import { useState, useRef } from "react";
import { motion } from 'framer-motion';
import { loaderVariants, slideIn } from "../utils/motion";
import { FaTelegram } from "react-icons/fa";

import { AiFillTwitterCircle, AiOutlineCopy, AiOutlineArrowRight, AiOutlineTwitter, AiOutlineRight, AiOutlineWallet } from "react-icons/ai"
import Button from "../utils/Button";
import AnimeLogo from "../utils/AnimeLogo";
import CA from "../utils/Ca";
import AOS from 'aos';
import 'aos/dist/aos.css'


const Hero = ({ pryColor
    , secColor }
) => {
    AOS.init({ once: true });

    const [isCopied, setIsCopied] = useState(false);
    const handleCopied = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 5000)
    }

    const contractRef = useRef();

    const copyContractText = () => {
        const text = contractRef.current.textContent;
        navigator.clipboard.writeText(text);
        console.log('Text copied: ' + text);
        handleCopied();
    };

    const buttonStyle = "bg-[#6495ED] text-[#fff] py-2 px-4 md:px-5 text-14px md:text-[18px] transform duration-300 hover:scale-110 rounded-md"

    return (<div className=" relative bg-gray-100 font-spectral hero-section w-[100%] mx-auto  flex  flex-col px-4 p-20 md:px-4 mt-10 py-20 items-center justify-center pt-0 gap-2 md:gap-5">
        <div className="">



            {/* Main Content */}
            <main className="container mx-auto mt-8">
                {/* Featured Article */}
                <article className="mb-8">
                    <h2 className="text-3xl font-bold mb-4">Breaking News</h2>
                    <p className="text-gray-600 mb-2">January 31, 2024</p>
                    <p className="text-lg leading-relaxed">
                        Greed  is Good.
                        Greed is right.
                        Greed works.
                        Greed clarifies, cuts through, and captures the essence of the evolutionary spirit.
                        Greed, in all of its forms -- greed for life, for money, for love, knowledge -- has marked the upward surge of mankind.
                        And greed -- you mark my words -- will not only save Crypto, but that other malfunctioning corporation called the USA / any country in the world controlling the economies.
                    </p>
                </article>
                <hr />
                <br />
                <div className="grid grid-cols-2 md:grid-cols-4 gap-5 bg-[#000] p-2 items-center">
                    <img src="../img/g3.jpg" alt="g1" />
                    <img src="../img/g2.png" alt="g1" />
                    <img src="../img/g1.jpeg" alt="g1" />
                    <img src="../img/g4.jpg" alt="g1" />
                </div>
                <br />
                <hr />
                <br />

                <h3 className="text-xl font-bold mb-2">Our Partners</h3>

                {/* Other Articles */}
                <div className="grid grid-cols-2 lg:grid-cols-4 items-center gap-8">
                    {/* Article 1 */}
                    <article className="mb-4">
               
                       <a href="#"><img src="../img/dex.png" className="w-[250px]" alt="" /></a>
                    </article>

                    {/* Article 2 */}
                    <a href="#">
                        <article className="mb-4">
                        <img src="../img/cmc.png" className="w-[250px]" alt="" />
                        </article>
                    </a>

                    {/* Article 3 */}
                    <a href="#">
                        <article className="mb-4">
                        <img src="../img/ray.png" className="w-[250px]" alt="" />
                        </article>
                    </a>

                    {/* Article 4 */}
                    <a href="#">
                        <article className="mb-4">
                        <img src="../img/wsj.jpg" className="w-[250px]" alt="" />
                        </article>
                    </a>
                </div>
                <br /><hr /><br />
            </main>

            {/* Footer */}
            <footer className="bg-gray-800 md:hidden text-white py-4">
        <div className=" mx-auto text-center">
          <p>&copy; 2024 The Wall Street Journal</p>
        </div>
      </footer>
        
        </div>
    </div>);
}

export default Hero;